/**======================================================================
=========================================================================
Template Name: DashboardKit - Most Complete Bootstrap Admin Template
Author: Phoenixcoded
Support: support@phoenixcoded.net
File: style.css
=========================================================================
=================================================================================== */
[data-pc-preset=preset-1] .pc-container {
  background: #e3f2fd;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item.active > .pc-link .pc-micon svg, [data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item:focus > .pc-link .pc-micon svg, [data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link .pc-micon i,
[data-pc-preset=preset-1] .pc-sidebar .pc-item:hover > .pc-link .pc-micon svg {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item.active .pc-link {
  font-weight: 500;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item.active > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item:focus > .pc-link, [data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item:hover > .pc-link {
  background: #e1d8f1;
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item.active > .pc-link i, [data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item:focus > .pc-link i, [data-pc-preset=preset-1] .pc-sidebar .pc-navbar > .pc-item:hover > .pc-link i {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-sidebar .pc-submenu .pc-item:hover:before, [data-pc-preset=preset-1] .pc-sidebar .pc-submenu .pc-item.active:before {
  background: #673ab7;
}
[data-pc-preset=preset-1] a,
[data-pc-preset=preset-1] .btn-link {
  --bs-link-color: $pc-primary;
}
[data-pc-preset=preset-1] .page-link {
  color: #2196f3;
}
[data-pc-preset=preset-1] .page-link:hover {
  background: #d3eafd;
}
[data-pc-preset=preset-1] .accordion-button:not(.collapsed) {
  color: #2196f3;
  background: #d3eafd;
}
[data-pc-preset=preset-1] .progress-bar {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .page-item.active .page-link,
[data-pc-preset=preset-1] .list-group-item.active {
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .nav-link:hover, [data-pc-preset=preset-1] .nav-link:focus {
  color: #2196f3;
}
[data-pc-preset=preset-1] .nav-pills .nav-link.active,
[data-pc-preset=preset-1] .nav-pills .show > .nav-link {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .nav-pills .nav-link.active:hover, [data-pc-preset=preset-1] .nav-pills .nav-link.active:focus,
[data-pc-preset=preset-1] .nav-pills .show > .nav-link:hover,
[data-pc-preset=preset-1] .nav-pills .show > .nav-link:focus {
  color: #fff;
}
[data-pc-preset=preset-1] .swal2-styled.swal2-confirm {
  background-color: #2196f3 !important;
}
[data-pc-preset=preset-1] .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(33, 150, 243, 0.4) !important;
}
[data-pc-preset=preset-1] .datepicker-cell.selected {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .datepicker-view .week {
  color: #2196f3;
}
[data-pc-preset=preset-1] .form-select:focus,
[data-pc-preset=preset-1] .form-control:focus {
  border-color: #2196f3;
  box-shadow: 0 0 0 1px #2196f3;
}
[data-pc-preset=preset-1] .form-check-input:checked {
  background-color: #2196f3;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}
[data-pc-preset=preset-1] .flatpickr-day.selected, [data-pc-preset=preset-1] .flatpickr-day.startRange, [data-pc-preset=preset-1] .flatpickr-day.endRange {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .flatpickr-day.selected.inRange, [data-pc-preset=preset-1] .flatpickr-day.selected:focus, [data-pc-preset=preset-1] .flatpickr-day.selected:hover, [data-pc-preset=preset-1] .flatpickr-day.selected.prevMonthDay, [data-pc-preset=preset-1] .flatpickr-day.selected.nextMonthDay, [data-pc-preset=preset-1] .flatpickr-day.startRange.inRange, [data-pc-preset=preset-1] .flatpickr-day.startRange:focus, [data-pc-preset=preset-1] .flatpickr-day.startRange:hover, [data-pc-preset=preset-1] .flatpickr-day.startRange.prevMonthDay, [data-pc-preset=preset-1] .flatpickr-day.startRange.nextMonthDay, [data-pc-preset=preset-1] .flatpickr-day.endRange.inRange, [data-pc-preset=preset-1] .flatpickr-day.endRange:focus, [data-pc-preset=preset-1] .flatpickr-day.endRange:hover, [data-pc-preset=preset-1] .flatpickr-day.endRange.prevMonthDay, [data-pc-preset=preset-1] .flatpickr-day.endRange.nextMonthDay {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .choices__list--multiple .choices__item {
  background-color: #2196f3;
  border: 1px solid #2196f3;
}
[data-pc-preset=preset-1] .is-focused .choices__inner,
[data-pc-preset=preset-1] .is-open .choices__inner {
  border-color: #2196f3;
  box-shadow: 0 0 0 1px #2196f3;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary {
  background: #d3eafd;
  color: #2196f3;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary > i {
  color: #2196f3;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary:hover {
  background: #2196f3;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .text-primary {
  color: #2196f3 !important;
}
[data-pc-preset=preset-1] .bg-primary {
  background-color: #2196f3 !important;
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #d3eafd;
  color: #2196f3;
}
[data-pc-preset=preset-1] .icon-svg-primary {
  fill: #d3eafd;
  stroke: #2196f3;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-primary {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .text-hover-primary:hover {
  color: #2196f3 !important;
}
[data-pc-preset=preset-1] .b-primary {
  border: 1px solid #2196f3;
}
[data-pc-preset=preset-1] .border-bottom-primary td {
  border-bottom: 1px solid #2196f3;
}
[data-pc-preset=preset-1] .border-bottom-primary th {
  border-bottom: 1px solid #2196f3 !important;
}
[data-pc-preset=preset-1] .fill-primary {
  fill: #2196f3;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2196f3;
  --bs-btn-border-color: #2196f3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1c80cf;
  --bs-btn-hover-border-color: #1a78c2;
  --bs-btn-focus-shadow-rgb: 66, 166, 245;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a78c2;
  --bs-btn-active-border-color: #1971b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2196f3;
  --bs-btn-disabled-border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-outline-primary {
  --bs-btn-color: #2196f3;
  --bs-btn-border-color: #2196f3;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #2196f3;
  --bs-btn-hover-border-color: #2196f3;
  --bs-btn-focus-shadow-rgb: 33, 150, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #2196f3;
  --bs-btn-active-border-color: #2196f3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2196f3;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-light-primary.coupon-btn {
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #2196f3;
  color: #fff;
  border-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary .material-icons-two-tone,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #2196f3;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #145a92;
  --bs-alert-bg: #d3eafd;
  --bs-alert-border-color: #bce0fb;
}
[data-pc-preset=preset-1] .alert-primary .alert-link {
  color: #104875;
}
[data-pc-preset=preset-1] .badge.bg-light-primary {
  background: #d3eafd;
  color: #2196f3;
  border-color: #d3eafd;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #2196f3;
  background-color: #2196f3;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #d3eafd;
  background-color: #d3eafd;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232196f3' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232196f3'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232196f3'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary {
  background: #e1d8f1;
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary > i {
  color: #673ab7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary:hover {
  background: #673ab7;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .text-secondary {
  color: #673ab7 !important;
}
[data-pc-preset=preset-1] .bg-secondary {
  background-color: #673ab7 !important;
}
[data-pc-preset=preset-1] .bg-light-secondary {
  background: #e1d8f1;
  color: #673ab7;
}
[data-pc-preset=preset-1] .icon-svg-secondary {
  fill: #e1d8f1;
  stroke: #673ab7;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-secondary {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .text-hover-secondary:hover {
  color: #673ab7 !important;
}
[data-pc-preset=preset-1] .b-secondary {
  border: 1px solid #673ab7;
}
[data-pc-preset=preset-1] .border-bottom-secondary td {
  border-bottom: 1px solid #673ab7;
}
[data-pc-preset=preset-1] .border-bottom-secondary th {
  border-bottom: 1px solid #673ab7 !important;
}
[data-pc-preset=preset-1] .fill-secondary {
  fill: #673ab7;
}
[data-pc-preset=preset-1] .btn-secondary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #58319c;
  --bs-btn-hover-border-color: #522e92;
  --bs-btn-focus-shadow-rgb: 126, 88, 194;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #522e92;
  --bs-btn-active-border-color: #4d2c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #673ab7;
  --bs-btn-disabled-border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-outline-secondary {
  --bs-btn-color: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #673ab7;
  --bs-btn-hover-border-color: #673ab7;
  --bs-btn-focus-shadow-rgb: 103, 58, 183;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #673ab7;
  --bs-btn-active-border-color: #673ab7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #673ab7;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .btn-light-secondary {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-light-secondary.coupon-btn {
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary.focus, [data-pc-preset=preset-1] .btn-light-secondary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-secondary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-secondary.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-secondary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-secondary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-secondary .material-icons-two-tone,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-secondary .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-link-secondary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-secondary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .btn-link-secondary:hover {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-link-secondary.focus, [data-pc-preset=preset-1] .btn-link-secondary:focus {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-secondary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-secondary.dropdown-toggle {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-secondary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-secondary {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .alert-secondary {
  --bs-alert-color: #3e236e;
  --bs-alert-bg: #e1d8f1;
  --bs-alert-border-color: #d1c4e9;
}
[data-pc-preset=preset-1] .alert-secondary .alert-link {
  color: #321c58;
}
[data-pc-preset=preset-1] .badge.bg-light-secondary {
  background: #e1d8f1;
  color: #673ab7;
  border-color: #e1d8f1;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:checked {
  border-color: #673ab7;
  background-color: #673ab7;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked {
  border-color: #e1d8f1;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23673ab7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-secondary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-secondary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary-dark {
  background: #d2e4f8;
  color: #1C76DA;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary-dark > i {
  color: #1C76DA;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary-dark:hover {
  background: #1C76DA;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-primary-dark:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .text-primary-dark {
  color: #1C76DA !important;
}
[data-pc-preset=preset-1] .bg-primary-dark {
  background-color: #1C76DA !important;
}
[data-pc-preset=preset-1] .bg-light-primary-dark {
  background: #d2e4f8;
  color: #1C76DA;
}
[data-pc-preset=preset-1] .icon-svg-primary-dark {
  fill: #d2e4f8;
  stroke: #1C76DA;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-primary-dark {
  background-color: #1C76DA;
}
[data-pc-preset=preset-1] .text-hover-primary-dark:hover {
  color: #1C76DA !important;
}
[data-pc-preset=preset-1] .b-primary-dark {
  border: 1px solid #1C76DA;
}
[data-pc-preset=preset-1] .border-bottom-primary-dark td {
  border-bottom: 1px solid #1C76DA;
}
[data-pc-preset=preset-1] .border-bottom-primary-dark th {
  border-bottom: 1px solid #1C76DA !important;
}
[data-pc-preset=preset-1] .fill-primary-dark {
  fill: #1C76DA;
}
[data-pc-preset=preset-1] .btn-primary-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1C76DA;
  --bs-btn-border-color: #1C76DA;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1864b9;
  --bs-btn-hover-border-color: #165eae;
  --bs-btn-focus-shadow-rgb: 62, 139, 224;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #165eae;
  --bs-btn-active-border-color: #1559a4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1C76DA;
  --bs-btn-disabled-border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-outline-primary-dark {
  --bs-btn-color: #1C76DA;
  --bs-btn-border-color: #1C76DA;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1C76DA;
  --bs-btn-hover-border-color: #1C76DA;
  --bs-btn-focus-shadow-rgb: 28, 118, 218;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1C76DA;
  --bs-btn-active-border-color: #1C76DA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1C76DA;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .btn-light-primary-dark {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .btn-light-primary-dark.coupon-btn {
  border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-light-primary-dark .material-icons-two-tone {
  background-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-light-primary-dark:hover {
  background: #1C76DA;
  color: #fff;
  border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-light-primary-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary-dark.focus, [data-pc-preset=preset-1] .btn-light-primary-dark:focus {
  background: #1C76DA;
  color: #fff;
  border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-light-primary-dark.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-primary-dark:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary-dark:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary-dark.dropdown-toggle {
  background: #1C76DA;
  color: #fff;
  border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-light-primary-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-primary-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-primary-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary-dark,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary-dark {
  background: #1C76DA;
  color: #fff;
  border-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary-dark .material-icons-two-tone,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary-dark .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-link-primary-dark {
  background: transparent;
  color: #1C76DA;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary-dark .material-icons-two-tone {
  background-color: #1C76DA;
}
[data-pc-preset=preset-1] .btn-link-primary-dark:hover {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .btn-link-primary-dark.focus, [data-pc-preset=preset-1] .btn-link-primary-dark:focus {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .btn-link-primary-dark:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary-dark:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary-dark.dropdown-toggle {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary-dark,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary-dark {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .alert-primary-dark {
  --bs-alert-color: #114783;
  --bs-alert-bg: #d2e4f8;
  --bs-alert-border-color: #bbd6f4;
}
[data-pc-preset=preset-1] .alert-primary-dark .alert-link {
  color: #0e3969;
}
[data-pc-preset=preset-1] .badge.bg-light-primary-dark {
  background: #d2e4f8;
  color: #1C76DA;
  border-color: #d2e4f8;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary-dark:checked {
  border-color: #1C76DA;
  background-color: #1C76DA;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary-dark:checked {
  border-color: #d2e4f8;
  background-color: #d2e4f8;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%231C76DA' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%231C76DA'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary-dark:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary-dark:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary-dark:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary-dark:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(28, 118, 218, 0.25);
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%231C76DA'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary-dark {
  background: #ddd5ed;
  color: #542CA7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary-dark > i {
  color: #542CA7;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary-dark:hover {
  background: #542CA7;
  color: #fff;
}
[data-pc-preset=preset-1] .pc-header .pc-head-link.head-link-secondary-dark:hover > i {
  color: #fff;
}
[data-pc-preset=preset-1] .text-secondary-dark {
  color: #542CA7 !important;
}
[data-pc-preset=preset-1] .bg-secondary-dark {
  background-color: #542CA7 !important;
}
[data-pc-preset=preset-1] .bg-light-secondary-dark {
  background: #ddd5ed;
  color: #542CA7;
}
[data-pc-preset=preset-1] .icon-svg-secondary-dark {
  fill: #ddd5ed;
  stroke: #542CA7;
}
[data-pc-preset=preset-1] .material-icons-two-tone.text-secondary-dark {
  background-color: #542CA7;
}
[data-pc-preset=preset-1] .text-hover-secondary-dark:hover {
  color: #542CA7 !important;
}
[data-pc-preset=preset-1] .b-secondary-dark {
  border: 1px solid #542CA7;
}
[data-pc-preset=preset-1] .border-bottom-secondary-dark td {
  border-bottom: 1px solid #542CA7;
}
[data-pc-preset=preset-1] .border-bottom-secondary-dark th {
  border-bottom: 1px solid #542CA7 !important;
}
[data-pc-preset=preset-1] .fill-secondary-dark {
  fill: #542CA7;
}
[data-pc-preset=preset-1] .btn-secondary-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #542CA7;
  --bs-btn-border-color: #542CA7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #47258e;
  --bs-btn-hover-border-color: #432386;
  --bs-btn-focus-shadow-rgb: 110, 76, 180;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #432386;
  --bs-btn-active-border-color: #3f217d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #542CA7;
  --bs-btn-disabled-border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-outline-secondary-dark {
  --bs-btn-color: #542CA7;
  --bs-btn-border-color: #542CA7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #542CA7;
  --bs-btn-hover-border-color: #542CA7;
  --bs-btn-focus-shadow-rgb: 84, 44, 167;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #542CA7;
  --bs-btn-active-border-color: #542CA7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #542CA7;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark.coupon-btn {
  border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark .material-icons-two-tone {
  background-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark:hover {
  background: #542CA7;
  color: #fff;
  border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark:hover .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark.focus, [data-pc-preset=preset-1] .btn-light-secondary-dark:focus {
  background: #542CA7;
  color: #fff;
  border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark.focus .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary-dark:focus .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-secondary-dark:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-secondary-dark.dropdown-toggle {
  background: #542CA7;
  color: #fff;
  border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-light-secondary-dark:not(:disabled):not(.disabled).active .material-icons-two-tone, [data-pc-preset=preset-1] .btn-light-secondary-dark:not(:disabled):not(.disabled):active .material-icons-two-tone, .show > [data-pc-preset=preset-1] .btn-light-secondary-dark.dropdown-toggle .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-secondary-dark,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-secondary-dark {
  background: #542CA7;
  color: #fff;
  border-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-secondary-dark .material-icons-two-tone,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-secondary-dark .material-icons-two-tone {
  background-color: #fff;
}
[data-pc-preset=preset-1] .btn-link-secondary-dark {
  background: transparent;
  color: #542CA7;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-secondary-dark .material-icons-two-tone {
  background-color: #542CA7;
}
[data-pc-preset=preset-1] .btn-link-secondary-dark:hover {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .btn-link-secondary-dark.focus, [data-pc-preset=preset-1] .btn-link-secondary-dark:focus {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .btn-link-secondary-dark:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-secondary-dark:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-secondary-dark.dropdown-toggle {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-secondary-dark,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-secondary-dark {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .alert-secondary-dark {
  --bs-alert-color: #321a64;
  --bs-alert-bg: #ddd5ed;
  --bs-alert-border-color: #ccc0e5;
}
[data-pc-preset=preset-1] .alert-secondary-dark .alert-link {
  color: #281550;
}
[data-pc-preset=preset-1] .badge.bg-light-secondary-dark {
  background: #ddd5ed;
  color: #542CA7;
  border-color: #ddd5ed;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary-dark:checked {
  border-color: #542CA7;
  background-color: #542CA7;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary-dark:checked {
  border-color: #ddd5ed;
  background-color: #ddd5ed;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23542CA7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23542CA7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-secondary-dark:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-secondary-dark:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary-dark:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-secondary-dark:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(84, 44, 167, 0.25);
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-secondary-dark:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23542CA7'/%3e%3c/svg%3e");
}